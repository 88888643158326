import { notificationStore } from '@/services/Notifications/store'
import moment from 'moment'
import { notifyError } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    let delivery = null
    if (this.scope === 'management' || this.scope === 'onboarding') {
      delivery = 'management'
    } else if (
      this.scope === 'homeowner' ||
      (this.authUser.userType !== undefined && this.authUser.userType === 'HomeOwner')
    ) {
      delivery = 'homeowner'
    } else if (
      this.scope === 'vendor' ||
      (this.authUser.userType !== undefined && this.authUser.userType === 'VendorUser')
    ) {
      delivery = 'vendor'
    }

    console.debug('delivery=' + this.scope)

    this.currentNotificationIds = []

    await notificationStore
      .dispatch('getNotificationList', {
        hoaId: this.hoaId,
        deliveryType: delivery,
        filterStartDate: this.filterStartDate,
        filterEndDate: this.filterEndDate
      })
      .then(({ list }) => {
        if (list) {
          this.notifications = list

          this.initializeCurrentIds()

          console.debug('notifications=' + JSON.stringify(this.notifications))

          list.forEach(n => {
            if (n) {
              let metaDataFields =
                n.metadata && n.metadata.fields !== undefined && n.metadata.fields
                  ? n.metadata.fields
                  : null
              let content = n.content ? n.content : null
              const logLevel = _get(n, 'logLevel', '')

              if (logLevel === 'Warning' || logLevel === 'warning') {
                n.isWarning = true
              } else {
                n.isWarning = false
              }

              console.debug('metaDataFields=' + JSON.stringify(metaDataFields))
              console.debug('content=' + content)

              if (metaDataFields) {
                this.messages = metaDataFields.filter(f => f.displayType === 'message')

                this.labels = metaDataFields.filter(f => f.displayType === 'label-value')

                this.actions = metaDataFields.filter(f => f.displayType === 'action')

                //Process content for each
                if (this.messages) {
                  console.debug('in messages...')
                  this.messages.forEach(m => {
                    if (m && content) {
                      let matchOn = this.capitalizeFirstLetter(m.objectMemberName)

                      console.debug('matchOn=' + matchOn)

                      let parsedContent = ''
                      JSON.parse(content, (key, value) => {
                        if (key === matchOn) {
                          parsedContent = value
                        }
                      })

                      m.content = this.formatByDataType(parsedContent, m.dataType)
                    }
                  })

                  console.debug('parsed messages=' + JSON.stringify(this.messages))
                }

                if (this.labels) {
                  console.debug('in labels...')
                  this.labels.forEach(l => {
                    if (l && content) {
                      let matchOn = this.capitalizeFirstLetter(l.objectMemberName)

                      console.debug('matchOn=' + matchOn)

                      let parsedContent = ''
                      JSON.parse(content, (key, value) => {
                        if (key === matchOn) {
                          parsedContent = value
                        }
                      })

                      l.content = this.formatByDataType(parsedContent, l.dataType)
                    }
                  })

                  console.debug('parsed labels=' + JSON.stringify(this.labels))
                }

                if (this.actions) {
                  console.debug('in actions...')
                  this.actions.forEach(a => {
                    if (a && content) {
                      let matchOn = this.capitalizeFirstLetter(a.objectMemberName)

                      console.debug('matchOnAction=' + matchOn)

                      let parsedContent = ''
                      JSON.parse(content, (key, value) => {
                        if (key === matchOn) {
                          parsedContent = value

                          a.buttonDisplay = this.processNavigationLink(a.linkObjectType, 0)
                        }
                      })

                      a.content = this.formatByDataType(parsedContent, a.dataType)
                    }
                  })

                  console.debug('parsed actions=' + JSON.stringify(this.actions))
                }
              }
            }

            n.messages = this.messages
            n.labels = this.labels
            n.actions = this.actions
          })
        }
      })

    loadingComponent.close()
  },

  async filterByDateRange() {
    if (this.isDebug == true) console.debug('...in filterByDateRange')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const from = this.$refs.fromDateComponent.getValue()
    const to = this.$refs.toDateComponent.getValue()

    if (!from || !from.year) {
      notifyError(`Please select valid dates.`)
      return
    }

    if (this.isDebug == true)
      console.debug('from=' + JSON.stringify(from) + ' - ' + JSON.stringify(to))

    this.filterStartDate = `${from.month}/${from.day}/${from.year}`
    this.filterEndDate = `${to.month}/${to.day}/${to.year}`

    await this.reload()

    loadingComponent.close()
  },

  formatByDataType(content, dataType) {
    let formattedContent = content

    if (dataType && content) {
      if (dataType === 'decimal' && typeof content === 'number') {
        formattedContent = Number.parseFloat(content).toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      } else if (dataType === 'currency' && typeof content === 'number') {
        formattedContent = `$${Number.parseFloat(content)
          .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
          .toString()}`
      } else if (dataType === 'date') {
        formattedContent = moment(content, 'YYYY-MM-DD').format('MM-DD-YYYY')
      } else if (dataType === 'date-time') {
        formattedContent = moment(content, 'YYYY-MM-DD HH:mm:ss').format('MM-DD-YYYY h:mm a')
      }
    }

    return formattedContent
  },

  initializeCurrentIds() {
    this.currentNotificationIds = []

    if (this.notifications && this.notifications.length > 0) {
      this.notifications.forEach(f => {
        if (f) {
          this.currentNotificationIds.push(f.notificationID)
        }
      })
    }
  },

  async dismissAll() {
    console.debug('in dismiss all' + JSON.stringify(this.currentNotificationIds))

    const payload = {
      notificationIDs: this.currentNotificationIds
    }

    if (this.currentNotificationIds && this.currentNotificationIds.length > 0) {
      await notificationStore.dispatch('setDelivered', {
        payload,
        done: async () => {
          await this.reload()

          //reset top nav notification alert count
          $('#topNavNotifications').text(this.notifications.length)
        }
      })
    }
  },

  capitalizeFirstLetter(stringParam) {
    return stringParam.charAt(0).toUpperCase() + stringParam.slice(1)
  },

  processActionRedirect(link) {
    if (link) {
      this.$router.push(link)
    }
  },

  async confirmDismissal(notification) {
    this.$buefy.dialog.confirm({
      title: 'Dismiss Notification',
      message: `Are you sure you want to <b>dismiss</b> this notification, '${notification
        .notificationType.name || ''}'?`,
      confirmText: 'Dismiss Notification',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.processDismiss(notification.notificationID)
    })
  },

  async processDismiss(id) {
    let currentNotificationId = []
    currentNotificationId.push(id)

    const payload = {
      notificationIDs: currentNotificationId
    }

    if (id) {
      await notificationStore.dispatch('setDelivered', {
        payload,
        done: async () => {
          await this.reload()

          //reset top nav notification alert count
          $('#topNavNotifications').text(this.notifications.length)
        }
      })
    }
  },

  processNavigationLink(codeName, id) {
    if (codeName === 'work-order') {
      if (id === 0) {
        return 'Work Order'
      } else if (this.scope === 'management' || this.scope === 'vendor') {
        const link = `/workOrderTabs/${id}/workorderDetails#tab`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'work-order-request') {
      if (id === 0) {
        return 'Work Order Request'
      } else {
        let link = ''
        if (this.scope === 'homeowner') {
          link = `/workorderpromotetabs/${id}/workorderRequestDetail#tab`
        } else if (this.scope === 'management') {
          link = `/workorderpromotetabs/${id}/workorderRequestPromote#tab`
        } else {
          notifyError('This notification page is not available in this portal.')
        }

        this.$router
          .push({
            path: link,
            params: { id: id }
          })
          .catch(() => {})
      }
    } else if (codeName === 'architectural-submission') {
      if (id === 0) {
        return 'Architectural Submission'
      } else if (this.scope === 'management' || this.scope === 'homeowner') {
        const link = `/architecturaltabs/${id}/architecturalRequestDetails#tab`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'violation') {
      if (id === 0) {
        return 'Violation'
      } else if (this.scope === 'management' || this.scope === 'homeowner') {
        const link = `/violationtabs/${id}/violationDetails#tab`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'unit') {
      if (id === 0) {
        return 'Property'
      } else if (this.scope === 'management') {
        const link = `/properties/${id}/0/0/overview#tab`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'owner') {
      if (id === 0) {
        return 'Owner'
      } else if (this.scope === 'homeowner') {
        if (
          this.authUser &&
          this.authUser.ownerUser !== undefined &&
          this.authUser.ownerUser.ownerID !== undefined &&
          this.authUser.ownerUser.ownerID === id
        ) {
          const link = `/properties/${id}/${this.authUser.ownerUser.ownerID}/${this.authUser.ownerUser.ownerID}/overview#tab`
          this.$router.push(link)
        }
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'ballot') {
      if (id === 0) {
        return 'eVoting'
      } else if (this.scope === 'management') {
        const link = `/evotingTabs/${id}/evotingDetails#tab`
        this.$router.push(link)
      } else if (this.scope === 'homeowner') {
        const link = `/evoting`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'gl-account') {
      notifyError('This notification page is not available in this portal.')
    } else if (codeName === 'survey') {
      if (id === 0) {
        return 'Survey'
      } else if (this.scope === 'management') {
        const link = `/communityCenter/surveyList#tab`
        this.$router.push(link)
      } else if (this.scope === 'homeowner') {
        const link = `/takeSurvey/${id}`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'invoice') {
      if (id === 0) {
        return 'Invoice'
      } else if (this.scope === 'management') {
        const link = `/ap-invoice-detail/${id}#tab`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'invoice-note') {
      if (id === 0) {
        return 'Invoice Notes'
      } else if (this.scope === 'management') {
        const link = `/invoicenotes/${id}#tab`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    } else if (codeName === 'community-calendar-event') {
      if (id === 0) {
        return 'Calendar Event'
      } else if (this.scope === 'management') {
        const link = `/communityCenter/calendar#tab`
        this.$router.push(link)
      } else {
        notifyError('This notification page is not available in this portal.')
      }
    }
  }
}
