export const data = () => ({
  isDebug: false,
  selectedRow: 0,
  labels: [],
  notifications: [],
  messages: [],
  actions: [],
  actionIds: [],
  scope: '',
  alerts: [],
  currentNotificationIds: [],
  startDate: null,
  endDate: null,
  showAll: false,
  showDismissAll: true,
  filterStartDate: null,
  filterEndDate: null
})
