<template>
  <PageContent :title="$t('notifications.title')">
    <div class="pb-6">
      <div filter-range :style="{ paddingBottom: '12px', float: 'left' }">
        <div
          :style="{
            textAlign: 'right',
            display: 'flex',
            ...(breakpoint === 'mobile'
              ? {
                  flexDirection: 'column'
                }
              : {
                  alignItems: 'right'
                })
          }"
        >
          <b-checkbox v-model="showAll">Show All Notifications from: </b-checkbox>
          <DatePicker ref="fromDateComponent" ariaPrefix="from" />
          <span class="field" :style="{ padding: '0 12px' }">TO</span>
          <DatePicker ref="toDateComponent" ariaLabel="to date" ariaPrefix="to" />
          <div class="field" :style="{ padding: '0 12px' }">
            <b-button
              :disabled="showDismissAll"
              class="go-button"
              size="is-small"
              type="is-info"
              rounded
              @click="filterByDateRange"
              >Go</b-button
            >
          </div>
          <div></div>
        </div>
      </div>
    </div>

    <div
      v-if="notifications && notifications.length && showDismissAll"
      class="pb-3"
      style="text-align: right;"
    >
      <b-button @click="dismissAll()">Dismiss All</b-button>
    </div>

    <div notifications-list>
      <div class="pb-5 pt-2" v-for="item in notifications" :key="item.notificationID">
        <article :class="item.isWarning === true ? `panel is-danger` : `panel is-info`">
          <p v-if="item.notificationType && item.notificationType.name" class="panel-heading">
            {{ item.notificationType.name }}
          </p>
          <div class="p-5">
            <div class="">
              <div
                v-if="item.deliveredDate === null"
                class="float-right float-top"
                style="margin-top: 0px;"
              >
                <b-button type="is-info is-light" @click="confirmDismissal(item)">Dismiss</b-button>
              </div>
              <div
                class="pb-2 notification-content"
                v-for="message in item.messages"
                :key="message.objectMemberName"
              >
                {{ message.content }}
              </div>
            </div>

            <div class="pt-5" v-if="item.labels">
              <div class="pb-2" v-for="lbl in item.labels" :key="lbl.objectMemberName">
                {{ `${lbl.label}: ${lbl.content}` }}
              </div>
            </div>

            <div class="columns pt-5" v-if="item.actions">
              <span
                class="pb-2 column is-2"
                v-for="act in item.actions"
                :key="act.objectMemberName"
              >
                <b-button
                  type="is-primary"
                  @click="processNavigationLink(act.linkObjectType, act.content)"
                  >{{ act.buttonDisplay }}</b-button
                >
              </span>
            </div>
          </div>
        </article>
      </div>
      <EmptyList v-if="notifications.length === 0" />
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import EmptyList from '@/components/EmptyList'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { mapGetters, mapState } from 'vuex'
import { appId } from '@/config'
import DatePicker from '@/components/inputs/DatePicker'

export default {
  components: {
    EmptyList,
    PageContent,
    DatePicker
  },

  computed: {
    ...mapGetters('user', ['authUser', 'loggedIn', 'hoaId']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  watch: {
    async showAll() {
      console.debug('showAll=' + this.showAll)
      if (this.showAll === true) {
        this.showDismissAll = false
      } else {
        this.showDismissAll = true
        this.filterStartDate = null
        this.filterEndDate = null

        await this.reload()
      }
    }
  },

  data,

  methods,

  async mounted() {
    await this.reload()

    this.scope = appId()
  },

  i18n: {
    messages: {
      en: {
        notifications: {
          title: 'Notifications'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-content {
  white-space: pre-line;
}
</style>
